import React, { useState , useEffect, useRef } from "react";
import axios from "axios";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import { FiSearch , FiUser } from "react-icons/fi";
import logo from "../../assets/img/logo/stockfit.png"
import { useNavigate } from 'react-router-dom';

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [user, setUser] = useState(null);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = async (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);

    if (newQuery.length > 0) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search/${newQuery}`);
        setResults(response.data.searchdata); 
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setResults([]);
    }
  };

  const openStock = async (symbol) => {
    const sym = symbol.replace(".NS",'')
    navigate(`/stock/${sym.toLowerCase()}`)
  }

  const logout = async () => {
    console.log("logout")
     try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/logout`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {
            localStorage.removeItem("token")
            navigate("/register")
        }
      }catch (error) {
//         console.log(error)
      }
    }

//     useEffect(() => {
// 	    const setuser = async () => {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_user/${token}`);
//         if (response.status === 200) {
//             console.log(response.data.user);
// 			setUser(response.data.user);
//           }
//         }
// 	    setuser();
// 	  }, [navigate]);

  return (
   <nav className="sticky top-0 z-40 sm:px-2 md:px-4 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 backdrop-blur-xl dark:bg-[#0b14374d]" style={{ backgroundColor: 'white' }}>
      <div className="ml-[12px] sm:ml-[6px]">
        <p className="shrink text-2xl md:text-[33px] capitalize text-navy-700 dark:text-white" style={{ marginLeft: '-4px' }}>
          <Link to="/" className="font-bold capitalize hover:text-navy-700 dark:hover:text-white">
            <img src={logo} alt="Stockefy Logo" className="h-[36px] sm:h-[40px] md:h-[46px]" />
          </Link>
        </p>
      </div>

      <div className="hidden lg:block">
         <ul className="flex justify-around p-4 md:gap-10 lg:gap-14">
           <li className="hover:text-blue-400 text-lg  cursor-pointer transition-colors duration-300 font-bold">
            <a href="/">Stock AI</a>
          </li>
          <li className="hover:text-blue-400 text-lg cursor-pointer transition-colors duration-300 font-bold">
            <a href="/screen">Stocks</a>
          </li>
          <li className="hover:text-blue-400 text-lg  cursor-pointer transition-colors duration-300 font-bold">
            <a href="/pricing">Pricing</a>
          </li>
          <li className="hover:text-blue-400 text-lg  cursor-pointer transition-colors duration-300 font-bold">
            <a href="/plan">Account</a>
          </li>
        </ul>
      </div>

      <div ref={searchRef} className="relative mt-[3px] flex h-[61px] sm:ml-2 md:max-w-[270px] md:flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 md:shadow-xl md:shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:max-w-[400px] xl:gap-2">
        <div style={{ border: "1px solid gray", flex: 1 }} className=" sm:static flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:max-w-[380px]">
          <p className=" pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            onChange={handleInputChange}
            type="text"
            placeholder="Stock"
            className="block h-full rounded-full bg-lightPrimary text-md font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white w-[80px] sm:w-fit"
          />
        </div>

        {results.length > 0 && (
          <div className="absolute top-full mt-2 w-full bg-white rounded-md shadow-lg dark:bg-navy-800">
            <ul>
              {results.map((result,index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-navy-700 cursor-pointer"
                  onClick={() => openStock(result.symbol)}
                >
                  {result.name}
                </li>
              ))}
            </ul>
          </div>
        )}

        <Dropdown
          button={
            <FiUser className="flex justify-center items-center cursor-pointer font-bold h-10 w-10 rounded-full bg-gray-100 p-2 text-bold" />
          }
          children={
            <div className="flex w-56 mt-3 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
{/*               <div className="p-4"> */}
{/*                 <div className="flex items-center gap-2"> */}
{/*                   <p className="text-sm font-bold text-navy-700 dark:text-white"> */}
{/*                   </p> */}
{/*                 </div> */}
{/*               </div> */}
              <div className="h-px w-full bg-gray-200 dark:bg-white/20" />
                <div className=" p-4">

                    <div className="lg:hidden">
                        <ul className="">
                            <li className="hover:text-blue-400 mt-3  text-md  cursor-pointer transition-colors duration-300">
                                <a href="/">Stock AI</a>
                            </li>
                            <li className="hover:text-blue-400 mt-3 text-md cursor-pointer transition-colors duration-300">
                                <a href="/screen">Stocks</a>
                            </li>
                            <li className="hover:text-blue-400 mt-3  text-md  cursor-pointer transition-colors duration-300">
                                <a href="/pricing">Pricing</a>
                            </li>
                            <li className="hover:text-blue-400 mt-3  text-md  cursor-pointer transition-colors duration-300">
                                <a href="/plan">Account</a>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-3">
                        <a href="/about"
                           className="mt-3 text-md font-medium text-gray-900 transition duration-150 ease-out hover:ease-in"
                        >About Us</a>
                    </div>
                    <div className="mt-3">
                        <a href="/policy"
                           className="mt-3 text-md font-medium text-gray-900 transition duration-150 ease-out hover:ease-in"
                        >Policy</a>
                    </div>
                    <div className="mt-3">
                        <a
                            onClick={() => logout()}
                            href="#"
                            className="mt-3 text-md font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                        >
                            Log Out
                        </a>
                    </div>
                </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
   </nav>
  );
};

export default Navbar;
