import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "components/navbar";
import { useParams } from 'react-router-dom';
import Card from "components/card";
import { MdBarChart} from "react-icons/md";
import { FaMagic } from "react-icons/fa";
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import TriplePulsingLoadingBars from 'components/loaders/pulsebar';
import LoadingSpinner from 'components/loaders/loading';
import TotalSpent from "../views/admin/default/components/TotalSpent";

export default function AiAnalysis() {
  const [message, setMessage] = useState("Analysis will appear here...");
  const [loading, setLoading] = useState(true);
  const [stockData, setStockData] = useState(null);
  const [finance_aipoints, setFinance_aipoints] = useState(0);
  const [call_aipoints, setCall_aipoints] = useState(0);
  const [price_aipoints, setPrice_aipoints] = useState(0);
  const [buisness_aipoints, setBusiness_aipoints] = useState(0);
  const [annual_aipoints, setAnnual_aipoints] = useState(0);
  const [currentAnalysis, setCurrentAnalysis] = useState("");
  const [generationtype, setGenerationtype] = useState("detailed");
  const [predicted , setPredicted] = useState({})

  const navigate = useNavigate();
  const { id } = useParams();

  function update_points(){
    console.log(currentAnalysis)
  }

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);


  useEffect(() => {
    const checkauth = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": token
        });
        if (response.status === 200) {
            window.scrollTo(0, 0);
            const fetchStockData = async () => {
              try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_stock/${id}`);
                const user_response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_user/${token}`);

                if(user_response.status === 200){
                  const user_data = user_response.data.user
                  setBusiness_aipoints(user_data['buisness_aipoints']);
                  setCall_aipoints(user_data['call_aipoints']);
                  setFinance_aipoints(user_data['finance_aipoints']);
                  setAnnual_aipoints(user_data['annual_aipoints']);
                  setPrice_aipoints(user_data['price_aipoints']);
                }

                if(response.status === 200){
                  const data = response.data.stock
                  setStockData(data);
                }
              } catch (err) {
                console.error('Error fetching stock data:', err);
              }
        };
        fetchStockData();
        }
      }catch (error) {
         navigate("/register")
      }
      setLoading(false);
    };
    checkauth();
  }, [navigate,id]);

	function animateRendering(buffer , chunk, delay=2) {
		const words = chunk.split(" ");
		let currentIndex = 0;

		function renderNextWord() {
			if (currentIndex < words.length) {
				setMessage((previous) => previous + words[currentIndex] + " ");
				currentIndex++;
			} else {
				clearInterval(renderInterval);
			}
		}
		const renderInterval = setInterval(renderNextWord, delay);
	}

    function openstock(stock_id){
        navigate(`/stock/${stock_id}`)
    }

	async function generate(type) {
	  let markdownBuffer = "";
	  setMessage(markdownBuffer);

	  const ws = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/aiStockAnalysis`);

	  ws.onopen = () => {
	    const token = localStorage.getItem("token");
	    ws.send(JSON.stringify({ "token": token, "symbol": id, "type": type , "generation_type" : generationtype}));
	  };

	  ws.onmessage = (event) => {
	    if (event.data === "1/END") {
	      update_points();
	    } else {
			markdownBuffer += event.data
	        if (event.data){
				setMessage(markdownBuffer);
	        }
	    }
	  };

	  ws.onclose = () => {
	    console.log('Disconnected from WebSocket');
	    ws.close();
	  };

	  if (type === "call") {
	    if (call_aipoints > 0) {
	      setCall_aipoints(call_aipoints - 1);
	    }
	  } else if (type === "finance") {
	    if (finance_aipoints > 0) {
	      setFinance_aipoints(finance_aipoints - 1);
	    }
	  } else if (type === "buisness") {
	    if (buisness_aipoints > 0) {
	      setBusiness_aipoints(buisness_aipoints - 1);
	    }
	  } else if (type === "annual") {
	    if (annual_aipoints > 0) {
	      setAnnual_aipoints(annual_aipoints - 1);
	    }
	  }else if (type === "price") {
	    if (price_aipoints > 0) {
	      setPrice_aipoints(price_aipoints - 1);
	    }
	  }
	}

   function cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const Changetype = (event) => {
    setGenerationtype(event.target.value); // Update state based on selected option
  };

  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
	        <Navbar
              logoText={"Horizon UI Tailwind React"}
              brandText="Stockfit"
            />
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  return (
    <div className="flex h-full w-full" style={{"height" : "100vh"}}>
      <div className="h-full w-full dark:!bg-navy-900" style={{'backgroundColor' : "#f2f5ff"}}>
        <main className={`h-full flex-none transition-all`}>
          <div className="h-full">
            <Navbar
              logoText={"hello"}
              brandText="Stockfit"
            />
            <Card extra={"p-2 mx-2 pt-8 mb-6 mt-4 md:p-8 md:mx-6 "}>
	            <div className="md:flex ml-2 items-center text-4xl font-bold text-navy-700">
	            <div className="flex items-center">
	                 <MdBarChart className="h-7 w-7 mr-2 text-brand-500" />
				      <p className="text-3xl font-bold leading-4">
				            <span>{stockData ? cap(stockData.LongName) : ''}</span>
			          </p>
		         </div>
                    <a className="text-lg text-brand-500 md:ml-8" href={stockData ? stockData.Website : ''}>
                        {stockData ? stockData.Website : ''}
                    </a>
                </div>
                <div className="mt-6">
                    <button onClick={() => generate("buisness")}
                            className="mr-4 pl-4 pr-4 linear mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        <div className="flex items-center">
                            <FaMagic size={18} className="mr-4" color="white"/>
                            <span className="hidden md:block">Business AI Analysis</span>
                            <span className="block md:hidden">Business AI</span>
                            <div
                                className="ml-3 rounded-xl bg-lightPrimary text-brand-500 w-[25px]">{buisness_aipoints}</div>
                        </div>
                    </button>

                    <button onClick={() => generate("call")}
                            className="sm:mr-4 pl-4 pr-4 linear mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        <div className="flex items-center">
                            <FaMagic size={18} className="mr-4" color="white"/>
                            <span className="hidden md:block">Transcript AI Analysis</span>
                            <span className="block md:hidden">Transcript AI</span>
                            <div
                                className="ml-3 rounded-xl bg-lightPrimary text-brand-500 w-[25px]">{call_aipoints}</div>
                        </div>
                    </button>

                    <button onClick={() => generate("finance")} type="submit"
                            className="mr-4 pl-4 pr-4 linear mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        <div className="flex items-center">
                            <FaMagic size={18} className="mr-4" color="white"/>
                            <span className="hidden md:block">Financial AI Analysis</span>
                            <span className="block md:hidden">Financial AI</span>
                            <div
                                className="ml-3 rounded-xl bg-lightPrimary text-brand-500 w-[25px]">{finance_aipoints}</div>
                        </div>
                    </button>

                    <button onClick={() => generate("annual")} type="submit"
                            className="pl-4 pr-4 linear mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        <div className="flex items-center">
                            <FaMagic size={18} className="mr-4" color="white"/>
                            <span className="hidden md:block">Annual Report AI Analysis</span>
                            <span className="block md:hidden">Annual AI</span>
                            <div
                                className="ml-3 rounded-xl bg-lightPrimary text-brand-500 w-[25px]">{annual_aipoints}</div>
                        </div>
                    </button>

                    <button onClick={() => generate("price")} type="submit"
                            className="lg:ml-4 pl-4 pr-4 linear mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        <div className="flex items-center">
                            <FaMagic size={18} className="mr-4" color="white"/>
                            <span>Price Prediction AI</span>
                            <div
                                className="ml-3 rounded-xl bg-lightPrimary text-brand-500 w-[25px]">{price_aipoints}</div>
                        </div>
                    </button>
                </div>

                <div className="m-3 flex items-end">
                    <div>
                    <label htmlFor="options" className="block text-sm font-medium text-gray-700">Generation
                            Type</label>
                        <select id="options"
                                className="mt-1 block w-[200px] px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                value={generationtype} onChange={Changetype}>
                            <option value="detailed">Detailed</option>
                            <option value="summary">Summary</option>
                            <option value="long">Long</option>
                        </select>
                    </div>

                    <button onClick={() => openstock(id)} type="button"
                            className="ml-4 pl-4 pr-4 linear mt-2 rounded-xl bg-brand-500 py-[8px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                        <div className="flex items-center">
                            {/*<FaMagic size={18} className="mr-4" color="white"/>*/}
                            <span>Stock</span>
                        </div>
                    </button>
                </div>

                <Card extra="shadow-lg mt-1 p-4">
                    {message ? (
                        <ReactMarkdown
                            components={{
                                p: ({node, ...props}) => <p style={{marginBottom: '1em'}} {...props} />,
                                ul: ({node, ...props}) => <p style={{marginBottom: '1em'}} {...props} />,
                                pre: ({node, ...props}) => <pre
                                    style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}} {...props} />
                            }}
                        >{message}</ReactMarkdown>
                    ) : (
                        <TriplePulsingLoadingBars height={20} gap={12}/>
                    )}
                </Card>

            </Card>
          </div>
        </main>
      </div>
    </div>
  );
};