import React , { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from "components/navbar";
import LoadingSpinner from 'components/loaders/loading';
import MetaTags from "../components/seo/tags";

const App = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

   useEffect(() => {
    const invoke = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/invoke`);
      } catch (error) {}
    };
    invoke();
  }, []);

   const checkauth = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {
            setLoading(false);
			return true;
        }
      }catch (error) {
          setLoading(false);
          navigate("/register")
          return false;
      }
    };

  const handlePayment = async (planName) => {
    try {
        const result = await checkauth();
        if (!result){
            navigate("/register")
        }
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/create_order/${planName}`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {
	        const options = {
	            key: process.env.REACT_APP_RAZORPAY_ID,
	            amount: response.data.order.amount,
	            currency: response.data.order.currency,
	            name: "Stockfit",
	            description: `${planName} Plan`,
	            order_id: response.data.order.id,
	            handler: async function (razorpayResponse) {
	               alert("Subscription updated successfully!");
	            },
	            prefill: {
	                name: response.data.email.split('@')[0] ,
	                email: response.data.email,
	                contact: "9999999999"
	            },
	            notes: {
	                address: "Address"
	            },
	            theme: {
	                color: "#667eea"
	            }
	        };
	        const rzp = new window.Razorpay(options);
	        rzp.open();
	    }
    } catch (error) {
		navigate("/register")
        console.error("Error creating order:", error);
    }
  };

  return (
    <div className="h-full">
	 <MetaTags
		title="StockFit - Pricing Plans for AI Stock Analysis"
		description="Choose the best pricing plan for AI-powered stock analysis. StockFit offers tailored pricing options to suit investors of all sizes. Get insights and improve your portfolio!"
		keywords="AI stock analysis pricing, stock analysis plans, investment tools pricing, StockFit pricing, affordable stock insights, intelligent investing, portfolio pricing"
		canonical="https://www.stockfit.com/pricing"
	 />

     <Navbar
       logoText={"Horizon UI Tailwind React"}
       brandText="Stockfit"
     />
     <section className="text-gray-700 body-font overflow-hidden border-t border-gray-200">
	  <div className="shadow-xl container px-8 pt-10 pb-10 mt-1 mx-auto flex flex-wrap" style={{"background":"white","borderRadius" : "20px"}}>
	    <div className="lg:w-1/4 w-full lg:mt-48 lg:mb-0 mt-0 mb-6 border-r  border-gray-300 lg:block">
	      <div className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden ">
	        <p className=" text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start -mt-px">Basic Stock Analysis</p>
	        <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Deep Business AI Analysis</p>
	        <p className=" text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Business Transcript AI Analysis</p>
	        <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Financial AI Analysis</p>
	        <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Annual Report AI Analysis</p>
			  <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Price Prediction AI</p>
			  <p className=" text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Plan Duration</p>
			  <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">24/7 Support</p>
	      </div>
	    </div>
	    <div className="flex lg:w-3/4 w-full flex-wrap lg:border border-gray-300 rounded-lg">
			<div
				className="lg:w-1/3 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
				<div className="px-2 text-center h-48 flex flex-col items-center justify-center">
					<h3 className="tracking-widest">BASIC</h3>
					<h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">500
						<span className="text-gray-600 text-base ml-1">INR</span>
					</h2>
					<span className="text-sm text-gray-600">Starter Pack</span>
				</div>
				<p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	        <span
				className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
					 className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
				</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					20</p>

				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					20</p>

				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					25</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					10</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					10</p>


				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">1
					Month</p>

				<p className="text-gray-900 text-center h-12 flex items-center justify-center">
					<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
						 strokeWidth="2.2" className="w-5 h-5 text-gray-800" viewBox="0 0 24 24">
						<path d="M18 6L6 18M6 6l12 12"></path>
					</svg>
				</p>

				<div className="border-t border-gray-300 p-6 text-center rounded-bl-lg">
					<button
						className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
						onClick={() => handlePayment("basic")}> Buy Basic
						<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
							 strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
							<path d="M5 12h14M12 5l7 7-7 7"></path>
						</svg>
					</button>
					<p className="text-xs text-gray-500 mt-3">Access essential features with budget-friendly plan.</p>
				</div>
			</div>
			<div className="lg:w-1/3 lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
				<span
					className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">POPULAR</span>
				<div className="px-2 text-center h-48 flex flex-col items-center justify-center">
					<h3 className="tracking-widest">PRO</h3>
					<h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">1000
						<span className="text-gray-600 text-base ml-1">INR</span>
					</h2>
					<span className="text-sm text-gray-600">Professional Pack</span>
				</div>
				<p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	          <span
				  className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
					 className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
				</p>
				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+ 45</p>
				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+ 45</p>
				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+ 60</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					25</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					25</p>

				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">2
					Months</p>

				<p className="text-gray-600 text-center h-12 flex items-center justify-center">
	          <span
				  className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
					 className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
				</p>

				<div className="p-6 text-center border-t border-gray-300">
					<button
						className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
						onClick={() => handlePayment("pro")}>Buy Pro
						<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
							 strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
							<path d="M5 12h14M12 5l7 7-7 7"></path>
						</svg>
					</button>
					<p className="text-xs text-gray-500 mt-3">Advanced benefits for enhanced productivity.</p>
				</div>
			</div>
			<div
				className="lg:w-1/3 w-full lg:mt-px border-2 border-gray-300 lg:border-none rounded-lg lg:rounded-none">
				<div className="px-2 text-center h-48 flex flex-col items-center justify-center">
					<h3 className="tracking-widest">PREMIUM</h3>
					<h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">2000
						<span className="text-gray-600 text-base ml-1">INR</span>
					</h2>
					<span className="text-sm text-gray-600">Elite Package</span>
				</div>
				<p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	        <span
				className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
					 className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
				</p>
				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+ 100</p>

				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+ 100</p>

				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+ 140</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					60</p>
				<p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
					60</p>

				<p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">3
					Months</p>

				<p className="text-gray-600 text-center h-12 flex items-center justify-center">
	          <span
				  className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
					 className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
				</p>

				<div className="p-6 text-center border-t border-gray-300">
					<button
						className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
						onClick={() => handlePayment("premium")}>Buy Premium
						<svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
							 strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
							<path d="M5 12h14M12 5l7 7-7 7"></path>
						</svg>
					</button>
					<p className="text-xs text-gray-500 mt-3">Experience exclusive benefits for maximum impact.</p>
				</div>
			</div>
		</div>
	  </div>
	 </section>
	</div>

  );
};

export default App;