import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "components/navbar";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';
import Card from "components/card";
import MetaTags from "../components/seo/tags";

function Policy() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const invoke = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/invoke`);
      } catch (error) {}
    };
    invoke();
  }, []);

  return (
	  <div className="flex w-full" style={{"height": "100vh"}}>
		 <MetaTags
			title="About StockFit - AI-Powered Stock Analysis Platform"
			description="Learn more about StockFit, the platform transforming stock analysis with AI. Our mission is to help investors make informed decisions with cutting-edge technology."
			keywords="About StockFit, AI stock analysis platform, intelligent investing, stock market insights, investment tools, stock evaluation, portfolio management, stock technology"
			canonical="https://www.stockfit.com/about"
		/>

		  <div className="w-full dark:!bg-navy-900" style={{'backgroundColor': "#f2f5ff"}}>
			  <main className={` flex-none transition-all md:pr-2`}>
				  <div>
					  <Navbar logoText={"hello"} brandText="Stockfit"/>
					  <Card
						  extra={'mt-1 mx-2 h-full md:mx-12 p-2 pt-6 md:p-6 lg:p-12 flex items-center justify-center'}>
						  <div className="container mx-auto px-4">
							  <div className="text-center mb-8">
								  <h1 className="text-4xl font-bold text-gray-900">About Stockfit</h1>
								  <p className="mt-4 text-gray-600">Meet the leaders driving Stockfit forward in
									  AI-powered stock analysis and business insights.</p>
							  </div>

							  <section className="bg-white shadow-md rounded-lg p-6 mb-8">
								  <h2 className="text-2xl font-semibold text-indigo-600 mb-4">StockFit AI</h2>

								  <div className="mb-6">
									  <h3 className="text-xl font-semibold">Yuvraj Jaiswal - Founder & CEO</h3>
									  <p className="text-gray-700 mt-2">
										  Yuvraj is the visionary behind Stockfit. With a background in computer
										  science and a passion for AI, Yuvraj leads the company’s mission to deliver
										  cutting-edge stock insights powered by AI. As CEO, Yuvraj oversees the
										  technical development and innovation at Stockfit. His expertise in machine learning and AI helps Stockfit remain at
										  the forefront of stock analysis technology.
									  </p>
								  </div>

								  <div className="mb-6">
									  <h3 className="text-xl font-semibold">StockFit Vision</h3>
									  <p className="text-gray-700 mt-2">
										  StockFit is committed to transforming the way individuals and investors
										  approach stock market analysis. Our vision is to make complex financial data
										  accessible and understandable for everyone, whether you are a seasoned
										  investor or just beginning your journey. We strive to create a platform that
										  not only simplifies the overwhelming world of stock market data but also
										  empowers users with insights that lead to informed decisions.
									  </p>
									  <p className="text-gray-700 mt-2">
										  Our mission goes beyond providing data – we aim to foster growth and
										  confidence in our users as they navigate the financial landscape. As the stock
										  market evolves, so too will StockFit, continually adapting to new trends,
										  technologies, and user needs. We are dedicated to being more than just a tool;
										  we seek to build lasting relationships with our community, offering
										  exceptional value and ensuring that our platform grows alongside our users.
									  </p>
									  <p className="text-gray-700 mt-2">
										  At StockFit, we believe in the power of data-driven decisions, and our
										  platform is designed to help users extract meaningful insights from the vast
										  sea of financial information. We are relentless in our pursuit of innovation,
										  constantly improving and expanding our capabilities to provide users with the
										  most accurate and timely stock analysis. With StockFit, you can trust that we
										  will be your partner in navigating the complexities of the stock market,
										  empowering you to achieve your financial goals with clarity and confidence.
									  </p>
								  </div>
							  </section>

							  <section className="bg-white shadow-md rounded-lg p-6 mb-8">
								  <h2 className="text-2xl font-semibold text-indigo-600 mb-4">Contact Us</h2>
								  <p className="mb-4">If you have any inquiries, feedback, or need assistance,
									  please
									  contact us:</p>

								  <ul className="list-none space-y-3">
									  <li><strong>Email:</strong> <a href="mailto:yuvrajjaiswal1632@gmail.com"
																	 className="text-blue-500 hover:underline">yuvrajjaiswal1632@gmail.com</a>
									  </li>
									  <li><strong>Address:</strong> 108 , Patnipura, Indore (MP)</li>
								  </ul>
							  </section>
						  </div>
					  </Card>
				  </div>
			  </main>
		  </div>
	  </div>
);
}

export default Policy;
