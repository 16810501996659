import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup } from "firebaseUtils";
import MetaTags from "../components/seo/tags";

export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkauth = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {
            navigate("/")
        }
      }catch (error) {

      }
      finally {
        setLoading(false);
      }
    };
    checkauth();
  }, [navigate]);

  const googleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register/google`, {
        "name" : user['displayName'],
        "email" : user['email'],
        "uid" : user['uid'],
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        navigate('/');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during login.");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register/password`, {
        "name" : name,
        "email" : email,
        "password" : password,
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        navigate('/otp');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during login.");
      }
    }
  };

  return (
    <div style={{overflow: 'hidden'}}>
      <MetaTags
          title="StockFit - Register for AI-Powered Stock Analysis"
          description="Create an account on StockFit and get access to AI-driven stock analysis, business insights, and investment tools. Make informed decisions today!"
          keywords="StockFit register, AI stock analysis sign up, stock insights account, investment tools registration, stock market registration, intelligent investing, portfolio sign up"
          canonical="https://www.stockfit.com/register"
      />

      <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900" >
        <main className={`mx-auto min-h-screen`}>
          <div className="pl-4 bg-brand-600" style={{'height' : '55px','display' : 'flex','alignItems':'center'}}>
            <p className="text-xl font-bold text-navy-700 text-white">Stockfit</p>
          </div>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start bg-white md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0">
              <div className=" mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className=" mt-4 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
                {/* Sign in section */}
                <div className="rounded-3xl shadow-xl border-2 p-10 w-full max-w-full flex-col items-center xl:max-w-[420px]">
                  <h4 className="mb-2.5 text-4xl font-bold  text-navy-700 dark:text-white">
                    Sign Up
                  </h4>
                  <p className="mb-9 ml-1 text-base text-gray-600">
                    Enter your name email and password to sign up!
                  </p>
                  {error && <p className="mb-3 text-red-500">{error}</p>}

                  <div onClick={googleSignUp} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                    <div  className="rounded-full text-xl">
                      <FcGoogle />
                    </div>
                    <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                      Sign Up with Google
                    </h5>
                  </div>

                  <div className="mb-6 flex items-center  gap-3">
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                    <p className="text-base text-gray-600 dark:text-white"> or </p>
                    <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                  </div>

                  <form onSubmit={handleSubmit}>
                    {/* Email */}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Name"
                      placeholder="John"
                      id="name"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Email"
                      placeholder="mail@simmmple.com"
                      id="email"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* Password */}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Password"
                      placeholder="Min. 8 characters"
                      id="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type='submit'
                      className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                      Create Account
                    </button>
                    <div className="mt-4">
                      <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
                        Already registered ?
                      </span>
                      <a
                        href="/login"
                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                      >
                        Login
                      </a>
                    </div>
                  </form>

                </div>
              </div>


              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
