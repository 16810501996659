import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "components/navbar";
import MainDashboard from "views/admin/default";
import { useParams } from 'react-router-dom';
import Card from "components/card";
import { MdBarChart } from "react-icons/md";
import { FaMagic } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';
import MetaTags from "../components/seo/tags";


export default function Stock() {
  const { id } = useParams();
  const [stockData, setStockData] = useState(null);
  const [quaterlyData, setQuaterlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [priceRange, setPriceRange] = useState("5Yr");
  const [loading, setLoading] = useState(true);
  const [annualReturns , setAnnualReturns] = useState([]);
  const [pricePrediction , setPricePrediction] = useState([]);
  const navigate = useNavigate();

  function changeRange(value){
    setPriceRange(value);
  }

  function formatDate(dateString,type="") {
	  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	  if(type){
	    const [year, month] = dateString.split('-');
		const formattedMonth = months[parseInt(month) - 1];
		return `${formattedMonth} ${year}`;
	  }else{
	    const [year, month, date] = dateString.split('-');
		const formattedMonth = months[parseInt(month) - 1];
		return `${date} ${formattedMonth} ${year}`;
	  }
	}

	function getMonth(timestamp) {
	    const date = new Date(timestamp);
	    return date.getMonth() + 1; // Adding 1 because getMonth() returns 0 for January
	}

	function getNext12Months(startMonth) {
	    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	    const startIndex = months.indexOf(months[startMonth-1]);

	    if (startIndex === -1) {
	        throw new Error("Invalid month code");
	    }
	    const next12Months = [];
	    for (let i = 0; i < 12; i++) {
	        next12Months.push(months[(startIndex + i) % 12]);
	    }
	    return next12Months;
	}


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchStockData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_stock/${id}`);
        if(response.status === 200){
          const data = response.data.stock
		  const formattedQuaterlyData = data.QuarterlyResults.map((item) => ({
		    Date: formatDate(item.Date),
		    Sales: item.Sales != null ? (item.Sales / 10000000).toFixed(0) : "N/A",
		    OperatingProfit: item.OperatingProfit != null ? (item.OperatingProfit / 1000000).toFixed(0) : "N/A",
		    OperatingMargin: item.OperatingMargin != null ? (item.OperatingMargin * 100).toFixed(1) + "%" : "N/A",
		    EPS: item.EPS != null ? item.EPS : "N/A",
		    NetProfitMargin: item.NetProfitMargin != null ? (item.NetProfitMargin * 100).toFixed(1) + "%" : "N/A",
		    NetProfit: item.NetProfit != null ? (item.NetProfit / 10000000).toFixed(0) : "N/A"
		  }));

		  const formattedYearlyData = data.AnnualResults.map((item) => ({
		    Date: item.Year,
		    Sales: item.Revenue != null ? (item.Revenue / 10000000).toFixed(0) : "N/A",
		    OperatingProfit: item.OperatingIncome != null ? (item.OperatingIncome / 1000000).toFixed(0) : "N/A",
		    OperatingMargin: item.OperatingMargin != null ? (item.OperatingMargin * 100).toFixed(1) + "%" : "N/A",
		    EPS: item.EPS != null ? item.EPS : "N/A",
		    NetProfitMargin: item.NetProfitMargin != null ? (item.NetProfitMargin * 100).toFixed(1) + "%" : "N/A",
		    NetProfit: item.NetIncome != null ? (item.NetIncome / 10000000).toFixed(0) : "N/A"
		  }));

		  setPricePrediction(data.PricePrediction.PredictedPrices)
		  setYearlyData(formattedYearlyData);
          setQuaterlyData(formattedQuaterlyData);
          setStockData(data);
        }
      } catch (err) {
        console.error('Error fetching stock data:', err);
      }
	  setLoading(false);
    };
    fetchStockData();
  }, [id]);

  useEffect(() => {
    const invoke = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/invoke`);
      } catch (error) {}
    };
    invoke();
  }, []);


function getOrderedValues(obj, range, n = 12) {
  let filterCondition;
  let dataPoints;

  const sortedData = Object.keys(obj)
    .sort()
    .map((key, index) => ({ key, value: obj[key], index }));

  switch (range) {
    case '5Yr':
      filterCondition = index => index % 3 === 0;
      dataPoints = sortedData.filter((_, index) => filterCondition(index));
      break;
    case '3Yr':
      filterCondition = index => index % 3 === 0;
      dataPoints = sortedData.slice(-750).filter((_, index) => filterCondition(index));
      break;
    case '1Yr':
      dataPoints = sortedData.slice(-250);
      break;
    case '6M':
      dataPoints = sortedData.slice(-150);
      break;
    case '3M':
      dataPoints = sortedData.slice(-80);
      break;
    case '1M':
      dataPoints = sortedData.slice(-30);
      break;
    default:
      dataPoints = [];
  }
	const prices = dataPoints.map(item => Math.round(item.value));

	if(priceRange === "6M" || priceRange === "3M" || priceRange === "1M"){
		const dates = dataPoints.map(item => formatDate(item.key));
		return { dates, prices };
	} else{
		const dates = dataPoints.map(item => formatDate(item.key,priceRange));
		return { dates, prices };
	}
}

	function getlinedata(range){
	  const lineChartData = [
	    {
	      name: "Price",
	      data: stockData ? getOrderedValues(stockData.ClosingPrices,range).prices : [],
	      color: "#008ffb",
	    }
	  ];
	  return lineChartData
	}

function getlineoption(range){
	const lineChartOptions = {
	  legend: {
	    show: false,
	  },
	  theme: {
	    mode: "light",
	  },
	  chart: {
	    type: "area",
	    toolbar: {
	      show: false,
	    },
	  },
	  dataLabels: {
	    enabled: false,
	  },
	  stroke: {
	    curve: "smooth",
	    width: 3, // Increased width for better visibility
	  },
	  tooltip: {
	    style: {
	      fontSize: "14px", // Increased font size for better readability
	      fontFamily: "Arial, sans-serif",
	      backgroundColor: "#333", // Slightly lighter background for contrast
	    },
	    theme: 'dark',
	    x: {
	      format: "dd/MM/yy HH:mm",
	    },
	  },
	  grid: {
	    show: true,
	    borderColor: '#e7e7e7', // Lighter grid lines for a cleaner look
	  },
	  xaxis: {
	  categories: getOrderedValues(stockData.ClosingPrices,range).dates,
	    axisBorder: {
	      show: false,
	      color: "#cccccc", // Subtle color for axis border
	    },
	    axisTicks: {
	      show: true,
	      color: "#cccccc", // Subtle color for ticks
	    },
	    labels: {
	      style: {
	        colors: "#555555", // Darker color for better readability
	        fontSize: "13px",
	        fontWeight: "500",
	      },
	    },
	    type: 'text',
	    tickAmount: 6,
	  },
	  yaxis: [
	    {
	    opposite: true,
	      axisBorder: {
	        show: false,
	        color: "#cccccc", // Subtle color for axis border
	      },
	      axisTicks: {
	        show: true,
	        color: "#cccccc", // Subtle color for ticks
	      },
	      labels: {
	        style: {
	          colors: "#333333", // Darker color for better readability
	          fontSize: "14px",
	          fontWeight: "500",
	        },
	      },
	      tickAmount: 6,
	      type: "text",
	      range: undefined,
	    },
	    {
	      opposite: false, // Show second y-axis on the right side
	      axisBorder: {
	        show: false,
	        color: "#cccccc", // Subtle color for axis border
	      },
	      axisTicks: {
	        show: true,
	        color: "#cccccc", // Subtle color for ticks
	      },
	      labels: {
	        style: {
	          colors: "#333333", // Darker color for better readability
	          fontSize: "14px",
	          fontWeight: "500",
	        },
	      },
	      tickAmount: 6,
	      type: "text",
	      range: undefined,
	    },
	  ],
	};
	return lineChartOptions;
	}

	const barChartData = [
	  {
	    name: "Predicted Price",
	    data: Object.values(pricePrediction),
	  },
	];

	const barChartOptions = {
	  chart: {
	    toolbar: {
	      show: false,
	    },
	  },
	  tooltip: {
	    style: {
	      fontSize: "12px",
	      fontFamily: undefined,
	      backgroundColor: "#000000"
	    },
	    onDatasetHover: {
	      style: {
	        fontSize: "12px",
	        fontFamily: undefined,
	      },
	    },
	    theme: "dark",
	  },
	  xaxis: {
		categories: stockData ? getNext12Months(getMonth(stockData.AnalysisDate)) : getNext12Months(1),
	    show: false,
	    labels: {
	      show: true,
	      style: {
	        colors: "#A3AED0",
	        fontSize: "14px",
	        fontWeight: "500",
	      },
	    },
	    axisBorder: {
	      show: false,
	    },
	    axisTicks: {
	      show: false,
	    },
	  },
	  yaxis: {

	    show: true,
	    color: "black",
	    labels: {
	      show: true,
	      style: {
	        colors: "#CBD5E0",
	        fontSize: "14px",
	      },
	    },
	  },
	  grid: {
	    show: false,
	    strokeDashArray: 5,
	    yaxis: {
	      lines: {
	        show: true,
	      },
	    },
	    xaxis: {
	      lines: {
	        show: false,
	      },
	    },
	  },
	  fill: {
	    type: "gradient",
	    gradient: {
	      type: "vertical",
	      shadeIntensity: 1,
	      opacityFrom: 0.7,
	      opacityTo: 0.9,
	      colorStops: [
	        [
	          {
	            offset: 0,
	            color: "#4318FF",
	            opacity: 1,
	          },
	          {
	            offset: 100,
	            color: "rgba(67, 24, 255, 1)",
	            opacity: 0.28,
	          },
	        ],
	      ],
	    },
	  },
	  dataLabels: {
	    enabled: false,
	  },
	  plotOptions: {
	    bar: {
	      borderRadius: 10,
	      columnWidth: "40px",
	    },
	  },
	};


  function cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
	        <Navbar
              logoText={"hello"}
              brandText="Stockfit"
            />
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  return (
    <div className="flex h-full w-full">
	<MetaTags
		title={stockData ? cap(stockData.LongName) : ''}
		description={stockData ? cap(stockData.BusinessSummary) : ''}
		keywords={`${stockData ? cap(stockData.LongName) : ''}, AI stock insights, stock market, stock analysis, portfolio management, investment`}
		canonical={stockData ? `https://stockfit.in/stock/${id}` : ''}
	/>
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main className={` h-full flex-none transition-all md:pr-2`}>
          <div className="h-full">
            <Navbar
              logoText={"Horizon UI Tailwind React"}
              brandText="Stockfit"
            />
            <div className="m-2 md:m-4">
            {stockData && (
              <Card extra='p-[30px] md:p-[40px]' style={{"margin" : "10px" , "marginTop" : "20px" , "marginBottom" : "0px"}}>
                <span className="text-4xl font-bold text-navy-700 ">
                  <div className="md:flex justify-between">
                    <div className="md:flex items-center text-4xl font-bold text-navy-700">
		            <div className="flex items-center">
		                 <MdBarChart className="h-7 w-7 mr-2 text-brand-500" />
					      <p className="text-2xl md:text-3xl font-bold ">
					            <span>{stockData ? cap(stockData.LongName) : ''}</span>
				          </p>
			         </div>
			          <a className="text-lg text-brand-500 md:ml-8" href={stockData ? stockData.Website : ''}>
	                      {stockData ? stockData.Website : ''}
	                  </a>
		            </div>
                    <div>
                      <form onSubmit={(e) => {
                        e.preventDefault();
                        navigate(`/aianalysis/${id}`)
                      }}>
                        <button type="submit" className="pl-8 pr-8 linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                          <div className="flex items-center">
                            <FaMagic size={18} className="mr-4" color="white" /> 
                            <span>AI Analysis</span>
                          </div>
                        </button>
                      </form>
                    </div>
                  </div>
                </span>
                <p className="mt-4">{cap(stockData.BusinessSummary)}</p>
              </Card>
            )}
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              {quaterlyData.length > 0 ? (
                <MainDashboard
                  lineData={getlinedata(priceRange)}
                  lineOption={getlineoption(priceRange)}
                  stockData={stockData}
                  tableDataColumns={quaterlyData}
                  yearlyData={yearlyData}
                  changeRangeFunc={changeRange}
                  currentRange={priceRange}
//                   predictedPrice={predictedPrice}
                  barChartData={barChartData}
                  barChartOptions={barChartOptions}
                />
              ) : null}
            </div>
            <div className="p-3">
            </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
 }
