import React, { useState , useEffect } from "react";
import axios from "axios";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useNavigate } from 'react-router-dom';
import { auth, provider, signInWithPopup } from "firebaseUtils";
import LoadingSpinner from 'components/loaders/loading';
import MetaTags from "../components/seo/tags";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    const checkauth = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": localStorage.getItem("token")
        });
        if (response.status === 200) {
            navigate("/")
        }
      }catch (error) {

      }
      finally {
        setLoading(false);
      }
    };
    checkauth();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/password`, {
        "email" : email,
        "password" : password,
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        navigate('/');
      }
    } catch (error) {
		console.log(error);
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during login.");
      }
    }
  };

  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/google`, {
        "name" : user['displayName'],
        "email" : user['email'],
        "uid" : user['uid'],
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        navigate('/');
      }
    } catch (error) {
		console.log(error);
      if (error.response) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during login.");
      }
    }
  };

  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  return (
    <div style={{overflow: 'hidden'}}>
      <MetaTags
          title="StockFit - Login to Your Account"
          description="Log in to StockFit and access personalized AI-powered stock analysis and investment insights. Start improving your portfolio today."
          keywords="StockFit login, stock analysis login, AI stock insights, stock market tools, stock analysis account, portfolio management login, investment login"
          canonical="https://www.stockfit.com/login"
      />

      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <div
            className="pl-4 bg-brand-600"
            style={{ height: "55px", display: "flex", alignItems: "center" }}
          >
            <p className="text-xl font-bold text-navy-700 text-white">
              Stockfit
            </p>
          </div>
          <div className="relative flex items-center justify-center">
            <div className="mx-auto flex w-full flex-col  pt-3 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 ">
              <div style={{"height" : "90vh"}} className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className="mt-8 mb-16 flex w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10">
                  <div className="rounded-3xl shadow-xl border-2 p-10 w-full max-w-full flex-col items-center xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                      Sign In
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600">
                      Enter your email and password to sign in!
                    </p>
                    {error && <p className="mb-3 text-red-500">{error}</p>}
                    <div onClick={googleSignIn} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
                      <div className="rounded-full text-xl">
                        <FcGoogle />
                      </div>
                      <h5 className="text-sm font-medium text-navy-700 dark:text-white">
                        Sign In with Google
                      </h5>
                    </div>
                    <div className="mb-6 flex items-center gap-3">
                      <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                      <p className="text-base text-gray-600 dark:text-white">
                        or
                      </p>
                      <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
                    </div>
                    <form onSubmit={handleLogin}>
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Email"
                        placeholder="mail@simmmple.com"
                        id="email"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Password"
                        placeholder="Min. 8 characters"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <div className="mb-4 flex items-center justify-between px-2">
                        <div className="flex items-center">
                          <Checkbox
                            checked={keepLoggedIn}
                            onChange={(e) =>
                              setKeepLoggedIn(e.target.checked)
                            }
                          />
                          <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                            Keep me logged In
                          </p>
                        </div>
                        <a
                          className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                          href="/"
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <button
                        type="submit"
                        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Sign In
                      </button>
                    </form>
                    <div className="mt-4">
                      <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                        Not registered yet?
                      </span>
                      <a
                        href="/register"
                        className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                      >
                        Create an account
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
