import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "components/navbar";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';
import Card from "components/card";
import MetaTags from "../components/seo/tags";

function Policy() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const invoke = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/invoke`);
      } catch (error) {}
    };
    invoke();
  }, []);

  return (
    <div className="flex w-full" style={{"height" : "100vh"}}>
		<MetaTags
			title="StockFit - Privacy Policy"
			description="Read StockFit's privacy policy to learn how we handle your data securely while providing AI-powered stock insights and analysis."
			keywords="StockFit privacy policy, data privacy, stock analysis data, AI stock tools, user data security, stock insights, investment privacy, StockFit security"
			canonical="https://www.stockfit.com/policy"
		/>
      <div className=" w-full dark:!bg-navy-900" style={{'backgroundColor' : "#f2f5ff"}}>
        <main className={` flex-none transition-all md:pr-2`}>
          <div>
            <Navbar
              logoText={"hello"}
              brandText="Stockfit"
            />
            <Card extra={'mt-1 mx-2 h-full md:mx-12 p-2 pt-6 md:p-6 lg:p-12 flex items-center justify-center'}>
			  <div class="container mx-auto px-4">

			    <div class="text-center mb-8">
			      <h1 class="text-4xl font-bold text-gray-900">Stockfit Terms & Policies</h1>
			      <p class="mt-4 text-gray-600">Your guide to understanding our terms, privacy policies, and refund policy.</p>
			    </div>

			    <section class="bg-white shadow-md rounded-lg p-6 mb-8">
			      <h2 class="text-2xl font-semibold text-indigo-600 mb-4">Terms and Conditions</h2>
			      <p class="mb-4">Welcome to Stockfit. By using our AI stock analysis service, you agree to comply with and be bound by the following terms and conditions.</p>

			      <ul class="list-disc list-inside space-y-3">
			        <li><strong>Use of Service:</strong> Stockfit provides AI-powered stock analysis and business insights. You agree to use it only for lawful purposes.</li>
			        <li><strong>Account Responsibility:</strong> You are responsible for your account credentials and all activities under your account.</li>
			        <li><strong>Accuracy of Information:</strong> While Stockfit strives to provide accurate data, we do not guarantee its reliability or completeness.</li>
			        <li><strong>Subscription and Payment:</strong> Services are offered on a subscription basis, and payment must be made in INR.</li>
			        <li><strong>Prohibited Activities:</strong> Users are prohibited from reverse-engineering or tampering with our Web apis.</li>
			        <li><strong>Termination of Service:</strong> Stockfit reserves the right to suspend or terminate accounts for violation of terms.</li>
			      </ul>
			    </section>

			    <section class="bg-white shadow-md rounded-lg p-6 mb-8">
			      <h2 class="text-2xl font-semibold text-indigo-600 mb-4">Privacy Policy</h2>
			      <p class="mb-4">We are committed to protecting your privacy. This section outlines how we collect, use, and protect your personal data.</p>

			      <ul class="list-disc list-inside space-y-3">
			        <li><strong>Information Collection:</strong> We collect personal information like your name, email, and payment details.</li>
			        <li><strong>Use of Information:</strong> Your data helps us provide and improve our services, process transactions, and communicate with you.</li>
			        <li><strong>Data Security:</strong> We use encryption to protect your data, but no method of transmission over the internet is 100% secure.</li>
			      </ul>
			    </section>

			    <section class="bg-white shadow-md rounded-lg p-6 mb-8">
			      <h2 class="text-2xl font-semibold text-indigo-600 mb-4">Refund and Cancellation Policy</h2>
			      <p class="mb-4">At Stockfit, all transactions are non-refundable. Once a subscription is activated, no refunds will be granted under any circumstances.</p>

			      <ul class="list-disc list-inside space-y-3">
			        <li><strong>Cancellation of Subscription:</strong> You subscription will be automatically deactivated after your plan validity ends.</li>
			        <li><strong>No Refunds:</strong> This policy is to ensure the continuity of service.</li>
			      </ul>
			    </section>

			    <section class="bg-white shadow-md rounded-lg p-6 mb-8">
			      <h2 class="text-2xl font-semibold text-indigo-600 mb-4">Pricing</h2>
			      <p>All prices for Stockfit's services are listed in INR (Indian Rupees). Prices are subject to change, and any changes will be communicated in advance.</p>
			    </section>

			    <section class="bg-white shadow-md rounded-lg p-6 mb-8">
			      <h2 class="text-2xl font-semibold text-indigo-600 mb-4">Contact Us</h2>
			      <p class="mb-4">If you have any inquiries, feedback, or need assistance, please contact us:</p>

			      <ul class="list-none space-y-3">
			        <li><strong>Email:</strong> <a href="mailto:yuvrajjaiswal1632@gmail.com" class="text-blue-500 hover:underline">yuvrajjaiswal1632@gmail.com</a></li>
			        <li><strong>Address:</strong> 108 , Patnipura , Indore(MP)</li>
			      </ul>
			    </section>
			  </div>
            </Card>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Policy;
