import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "components/navbar";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/loaders/loading';

function Plan() {
  const [loading, setLoading] = useState(true);
  const [user , setUser] = useState(null);
  const [plan , setPlan] = useState("free");
  const navigate = useNavigate();

	function formatFirebaseTimestamp(timestamp) {
	  const date = new Date(timestamp);
	  const options = {
	    timeZone: 'Asia/Kolkata',
	    year: 'numeric',
	    month: 'long',
	    day: 'numeric',
	  };

	  return date.toLocaleString('en-IN', options);
	}

  useEffect(() => {
    const checkauth = async () => {
      try {
        const token = localStorage.getItem("token")
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-token`,{
            "token": token
        });
        if (response.status === 200) {
	        try {
		        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_user/${token}`);
		        if(response.status === 200) {
		            const data = response.data.user;
		            setUser(data);
		            setPlan(data.plan);
		        }else{
		            navigate("/register")
		        }
	      } catch (error) {
	        navigate("/register")
	        console.error("Error fetching data:", error);
	      }
        }
      }catch (error) {
        navigate("/register")
      }
      finally {
        setLoading(false);
      }
    };
    checkauth();
  }, [navigate]);

  if (loading) {
    return (
	    <div style={{"height" : "80vh"}}>
	        <Navbar
              logoText={"Horizon UI Tailwind React"}
              brandText="Stockfit"
            />
            <LoadingSpinner></LoadingSpinner>
	    </div>
    )
  }

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full dark:!bg-navy-900" style={{'backgroundColor' : "#f2f5ff"}}>
        <main className={` h-full flex-none transition-all`}>
          <div className="h-full">
            <Navbar
              logoText={"hello"}
              brandText="Stockfit"
            />
            <div className=' mx-2 md:mx-12 '>
              <section className="text-gray-700 body-font overflow-hidden border-t border-gray-200">
	  <div className=" flex justify-center shadow-xl container px-8 pt-10 pb-10 mt-1 mx-auto flex flex-wrap" style={{"background":"white","borderRadius" : "20px"}}>
	    <div className="lg:w-1/4 w-full mb-16 border-r border-gray-200 lg:mt-48 lg:block lg:mb-0">
            <div
                className="mt-px border-t border-gray-300 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden">
                <p className=" text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start -mt-px">Basic
                    Stock Analysis</p>
                <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Deep
                    Business AI Analysis</p>
                <p className=" text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Business
                    Transcript AI Analysis</p>
                <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Financial
                    AI Analysis</p>
                <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Annual
                    Report AI Analysis</p>
                <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Annual
                    Price Prediction AI</p>
                <p className=" text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">Plan
                    Duration</p>
                <p className="text-gray-900 h-12 text-center px-4 flex items-center justify-center md:justify-start">24/7
                    Support</p>
            </div>
        </div>

          {plan === 'free' && (
              <div className="w-80 lg:mr-32 lg:border border-indigo-500 rounded-lg">
                  <div className="lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
                      <span
                          className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">CURRENT</span>

                      <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
                          <h3 className="tracking-widest">0 INR</h3>
                          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">FREE
                              <span className="text-gray-600 text-base ml-1"></span>
                          </h2>
                          <span className="text-sm text-gray-600">Expiry - Unlimited</span>
                      </div>
                      <p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	        <span
                className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                     className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
                      </p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          2</p>

                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          2</p>

                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          2</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          1</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          1</p>

                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">Unlimited</p>

                      <p className="text-gray-900 text-center h-12 flex items-center justify-center">
                          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                               strokeWidth="2.2" className="w-5 h-5 text-gray-800" viewBox="0 0 24 24">
                              <path d="M18 6L6 18M6 6l12 12"></path>
                          </svg>
                      </p>

                      <div className="border-t border-gray-300 p-6 text-center rounded-bl-lg">
                          <button
                              className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
                              onClick={() => navigate("/payment")}> Change Plan
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                   strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                          </button>
                          <p className="text-xs text-gray-500 mt-3">Access essential features with budget-friendly
                              plan.</p>
                      </div>
                  </div>
              </div>
          )}

          {plan === 'basic' && (
              <div className="w-80 lg:mr-32 lg:border border-indigo-500 rounded-lg">

                  <div className=" lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
                      <span
                          className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">CURRENT</span>

                      <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
                          <h3 className="tracking-widest">500 INR</h3>
                          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">BASIC
                              <span className="text-gray-600 text-base ml-1"></span>
                          </h2>
                          <span className="text-sm text-gray-600">{user && (
                              <p>Expire - {formatFirebaseTimestamp(user.plan_end)}</p>
                          )}</span>
                      </div>
                      <p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	        <span
                className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                     className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
                      </p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          20</p>

                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          20</p>

                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          25</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          10</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          10</p>

                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">1
                          Month</p>

                      <p className="text-gray-900 text-center h-12 flex items-center justify-center">
                          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                               strokeWidth="2.2" className="w-5 h-5 text-gray-800" viewBox="0 0 24 24">
                              <path d="M18 6L6 18M6 6l12 12"></path>
                          </svg>
                      </p>

                      <div className="border-t border-gray-300 p-6 text-center rounded-bl-lg">
                          <button
                              className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
                              onClick={() => navigate("/payment")}> Change Plan
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                   strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                          </button>
                          <p className="text-xs text-gray-500 mt-3">Access essential features with budget-friendly
                              plan.</p>
                      </div>

                  </div>
              </div>
          )}


          {plan === 'pro' && (
              <div className="w-80 lg:mr-32 lg:border border-indigo-500 rounded-lg">
                  <div className="lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
                      <span
                          className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">CURRENT</span>
                      <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
                          <h3 className="tracking-widest">1000 INR</h3>
                          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">PRO
                              <span className="text-gray-600 text-base ml-1"></span>
                          </h2>
                          <span className="text-sm text-gray-600">{user && (
                              <p>Expire - {formatFirebaseTimestamp(user.plan_end)}</p>
                          )}</span>
                      </div>
                      <p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	          <span
                  className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                     className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
                      </p>
                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+
                          45</p>
                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+
                          45</p>
                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+
                          60</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          25</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          25</p>

                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">2
                          Months</p>

                      <p className="text-gray-600 text-center h-12 flex items-center justify-center">
	          <span
                  className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                     className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
                      </p>

                      <div className="p-6 text-center border-t border-gray-300">
                          <button
                              className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
                              onClick={() => navigate("/payment")}> Change Plan
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                   strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                          </button>
                          <p className="text-xs text-gray-500 mt-3">Advanced benefits for enhanced productivity.</p>
                      </div>
                  </div>
              </div>
          )}


          {plan === 'premium' && (
              <div className="w-80 lg:mr-32 lg:border border-indigo-500 rounded-lg">
                  <div className="lg:-mt-px w-full mb-10 lg:mb-0 border-2 rounded-lg border-indigo-500 relative">
                      <span
                          className="bg-indigo-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">CURRENT</span>
                      <div className="px-2 text-center h-48 flex flex-col items-center justify-center">
                          <h3 className="tracking-widest">2000 INR</h3>
                          <h2 className="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">PREMIUM
                              <span className="text-gray-600 text-base ml-1"></span>
                          </h2>
                          <span className="text-sm text-gray-600">{user && (
                              <p>Expire - {formatFirebaseTimestamp(user.plan_end)}</p>
                          )}</span>
                      </div>
                      <p className=" text-gray-600 h-12 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-300">
	        <span
                className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                     className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
                      </p>
                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+
                          100</p>

                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+
                          100</p>

                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">+
                          140</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          60</p>
                      <p className="h-12 text-gray-900 px-6 text-center leading-relaxed flex items-center justify-center">+
                          60</p>

                      <p className="h-12 text-gray-900 text-center leading-relaxed flex items-center justify-center">3
                          Months</p>

                      <p className="text-gray-600 text-center h-12 flex items-center justify-center">
	          <span
                  className="w-5 h-5 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full flex-shrink-0">
	            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                     className="w-3 h-3" viewBox="0 0 24 24">
	              <path d="M20 6L9 17l-5-5"></path>
	            </svg>
	          </span>
                      </p>

                      <div className="p-6 text-center border-t border-gray-300">
                          <button
                              className="flex items-center mt-auto text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
                              onClick={() => navigate("/payment")}> Change Plan
                              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                   strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                              </svg>
                          </button>
                          <p className="text-xs text-gray-500 mt-3">Experience exclusive benefits for maximum
                              impact.</p>
                      </div>
                  </div>
              </div>
          )}


      </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Plan;
