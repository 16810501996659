import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "layouts/login";
import Register from "layouts/register";
import Stock from "layouts/stock";
import Screener from "./layouts/screener"
import Pricing from "./layouts/pricing"
import AiAnalysis from "layouts/aianalysis"
import Plan from "layouts/plan"
import AiSearch from "layouts/aisearch"
import Otp from "layouts/otp"
import Policy from "layouts/policy"
import About from "layouts/about"

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />]
      <Route path="/register" element={<Register />} />
      <Route path="/otp" element={<Otp/>}/>
      <Route path="/screen" element={<Screener />}/>
      <Route path="/stock/:id" element={<Stock/>}/>
      <Route path="/pricing" element={<Pricing/>}/>
      <Route path="/plan" element={<Plan/>}/>
      <Route path="/aianalysis/:id" element={<AiAnalysis/>}/>
      <Route path="/" element={<AiSearch/>}/>
      <Route path="/policy" element={<Policy/>}/>
      <Route path="/about" element={<About/>}/>
    </Routes>
  );
};

export default App;
